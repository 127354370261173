import axios from 'axios'
import store from '../store/index'
import jsonBigint from 'json-bigint'

const lint = jsonBigint({ storeAsString: true })

const baseurl = process.env.VUE_APP_BASE_URL
axios.defaults.timeout = 20000
axios.defaults.transformResponse = data => typeof data === 'string' && lint.parse(data)

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = baseurl
}

// --------------------------------------------------- axios config ---------------------------------------------------
// 添加一个请求拦截器
axios.interceptors.request.use((config) => {
    if (config.method.toLowerCase() === 'get') {
        config.params = config.params || {}
        config.params['_'] = (+new Date()) // 时间戳
    }
    // config.headers.appId = 'wx7f6f9f9eed48c0b2' // 小摩豆GD appId
    // config.headers.system = 'web'
    config.headers.platform = platform()
    config.headers['Cache-control'] = 'no-cache'
    config.headers.Expires = -1
    config.headers.Pragma = 'no-cache'
    return config
}, (error) => {
    return Promise.reject(error)
})

// 添加一个响应拦截器
axios.interceptors.response.use(response => {
    const prototype = Object.prototype.toString.call(response.data).toLowerCase()

    // 返回了二进制文件
    if (/blob/.test(prototype)) return response.data
    if (response.status === 200) {
        const data = response.data
        const code = data.code
        if (code === 200) {
            return Promise.resolve(data.data)
        } else if (code === 400 || code === 401) {
        } else {
            return Promise.reject(data.msg)
        }
    } else {
        return Promise.reject(new Error(response.statusText))
    }
}, function (error) {
    const err = JSON.parse(JSON.stringify(error))
    console.log('errObj', error)
    return Promise.reject(err.message)
})

const platform = function () {
    const { userAgent } = store.state
    if (userAgent === 'wx') return 'wechat'
    if (userAgent === 'ali') return 'alipay'
    return 'wechat'
}

export default axios
