import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";

const routes = [
    { path: "/home", name: "home", component: () => import("../views/Home.vue") },
    { path: "/operation", name: "operation", component: () => import("../views/Operation.vue") },
    { path: "/shop", name: "shop", component: () => import("../views/Shop.vue") },
    { path: "/website", name: "website", component: () => import("../views/Website.vue") },
    { path: "/userOperation", name: "userOperation", component: () => import("../views/UserOperation.vue") },
    { path: "/smartMarketing", name: "smartMarketing", component: () => import("../views/SmartMarketing.vue") },
    { path: "/drainagePlatform", name: "drainagePlatform", component: () => import("../views/DrainagePlatform.vue") },
    { path: "/smartPayment", name: "smartPayment", component: () => import("../views/SmartPayment.vue") },
    { path: "/advertising", name: "advertising", component: () => import("../views/Advertising.vue") },
    { path: "/wisFootMsg", name: "wisFootMsg", component: () => import("../views/WisFootMsg.vue") },
    { path: "/wisPicEars", name: "wisPicEars", component: () => import("../views/WisPicEars.vue") },
    { path: "/ksfm", name: "ksfm", component: () => import("../views/Ksfm.vue") },
    { path: "/paidMarket", name: "paidMarket", component: () => import("../views/PaidMarket.vue") },
    { path: "/case", name: "case", component: () => import("../views/Case.vue") },
    { path: "/win", name: "win", component: () => import("../views/Win.vue") },
    { path: "/aboutUs", name: "aboutUs", component: () => import("../views/AboutUs.vue") },
  ];
  
routes.push({
    path: '/', redirect: { name: 'home' }
})

const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior () {
    return { top: 0 }
  },
  routes,
});

// router.beforeEach(() => {
//     window.scrollTo(0, 0)
// })

export default router;
