<template>
    <div id="footerbar-components">
        <div class="footerbar-nav">
            <div class="left">
                <div class="first">
                    <img src="../assets/Footerbar/icon_customer_service.png"/>
                    <span>咨询热线</span>
                </div>
                <div class="second">400-829-9358</div>
                <div class="third">周一至周六 9:00 ～22:00</div>
                <div class="fourth">
                    <div class="left">
                        <img src="../assets/Footerbar/QR_follow.png"/>
                        关注我们
                    </div>
                    <div class="right">
                        <img src="../assets/Footerbar/QR_contact.png"/>
                        联系我们
                    </div>
                </div>
            </div> 
            <div class="right">
                <div class="first-column">
                    <div class="title">产品与服务</div>
                    <div class="nav">
                        <div>
                            <div>产品</div>
                            <div @click="tab('shop')">智慧管店</div>
                            <div @click="tab('website')">微官网</div>
                            <div @click="tab('userOperation')">智能用户运营</div>
                            <div @click="tab('smartMarketing')">智慧营销</div>
                            <div @click="tab('drainagePlatform')">引流平台</div>
                            <div @click="tab('smartPayment')">智慧支付</div>
                        </div>

                        <div>
                            <div>服务</div>
                            <div @click="tab('advertising')">广告投放</div>
                            <div @click="tab('operation')">私域运营</div>
                        </div>
                    </div>
                </div>

                <div class="first-column">
                    <div class="title">解决方案</div>
                    <div class="nav">
                        <div>
                            <div class="first" @click="tab('wisFootMsg')">智慧足疗</div>
                            <div @click="tab('wisPicEars')">智慧采耳</div>
                            <div @click="tab('ksfm')">K歌沐足</div>
                            <div @click="tab('paidMarket')">支付营销</div>
                        </div>
                    </div>
                </div>

                <div class="first-column">
                    <div class="title">联系我们</div>
                    <div class="nav">
                        <div>
                            <div class="first">联系方式</div>
                            <div class="white">400-829-9358</div>
                            <div>电子邮箱</div>
                            <div class="white">adm@xiaomodo.com</div>
                            <div>公司地址</div>
                            <div class="white">深圳市沙头街道天安社区泰然八路33号云松大厦十一层B01</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer" @click="doClickRecord()">© 2018 小摩豆 版权所有 粤ICP备15112271号 粤B2-20240677</div>
    </div>
</template>

<script>
    export default {
        name: "footerbar",
        data () {
            return {}
        },
        methods: {
            doClickRecord () {
                window.open('https://beian.miit.gov.cn/')
            },
            tab (type) {
                this.$router.push(type)
            }
        },
    }
</script>
<style scoped lang="scss">
#footerbar-components {
    >.footerbar-nav {
        height: 501px;
        background-color: #111111;
        padding-top: 80px;
        display: flex;
        justify-content: center;
        >.left {
            width: 304px;
            text-align: left;
            >.first {
                color: #ffffff;
                font-size: 16px;
                letter-spacing: 1px;
                display: flex;
                align-items: center;
                >span {
                    margin-left: 8px;
                }
            }
            >.second {
                color: #ffffff;
                letter-spacing: 3px;
                font-size: 40px;
                margin-top: 16px;
            }
            >.third {
                color: #ffffff;
                letter-spacing: 1px;
                font-size: 16px;
                margin-top: 16px;
            }
            >.fourth {
                margin-top: 40px;
                letter-spacing: 1px;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.6);
                display: flex;
                >.left {
                    text-align: center;
                    >img {
                        margin-bottom: 12px;
                    }
                }
                >.right {
                    text-align: center;
                    margin-left: 24px;
                    >img {
                        margin-bottom: 12px;
                    }
                }
            }
        }

        >.right {
            margin-left: -15px;
            display: flex;
            >.first-column {
                text-align: left;
                margin-left: 95px;
                >.title {
                    color: #ffffff;
                    font-size: 16px;
                    letter-spacing: 1px;
                    margin-bottom: 18px;
                }
                >.nav {
                    display: flex;
                    >div {
                        >div {
                            margin-bottom: 16px;
                            letter-spacing: 1px;
                            color: rgba(255, 255, 255, 0.6);
                            cursor: pointer;
                            &:nth-of-type(1) {
                                color: rgba(255, 255, 255, 0.9);
                            }
                        }
                        &:nth-of-type(2) {
                            margin-left: 95px;
                        }
                    }
                }
                &:nth-of-type(2), &:nth-of-type(3) {
                    >.nav {
                        >div {
                            >.first {
                                color: rgba(255, 255, 255, 0.6);
                            }
                            >.white {
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }

    >.footer {
        height: 70px;
        line-height: 70px;
        background-color: #343434;
        font-size: 16px;
        letter-spacing: 1px;
        color: rgba(255, 255, 255, 0.9);
        cursor: pointer;
    }
}
</style>
