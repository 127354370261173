<template>
  <header>
        <nav-bar ref="navbar"></nav-bar>
    </header>
    <div>
        <router-view />
        <div class="right-cion">
            <div class="one">
                <img src="./assets/icon_ontrial@2x.png"/>申请试用
                <i></i>
                <span></span>
            </div>
            <div class="two">
                <img src="./assets/icon_WeChat@2x.png"/>微信咨询
                <i></i>
                <span></span>
            </div>
            <div class="three">
                <img src="./assets/icon_phone@2x.png"/>电话咨询
                <span>
                    <i>电话咨询</i>
                    <i>400-829-9358</i>
                    <i>周一至周日 09:00～22:00</i>
                </span>
            </div>
        </div>
    </div>
    <footer>
        <footerbar></footerbar>
    </footer>
</template>

<script>
    import Footerbar from "@/components/FooterBar.vue";
    import NavBar from "@/components/NavBar.vue";

    export default {
        name: "website",
        components: { Footerbar, NavBar },
        data () {
            return {
                scrollTop: 0
            }
        },
        created () {
        },
        methods: {
        }
    }
</script>

<style lang="scss">
@import "./scss/common";
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-size: 16px;
    overflow: hidden;
    min-height: 100%;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-width: 1500px;
    position: relative;
  >div {
        padding-top: 70px;
  }
  .right-cion {
        width: 86px;
        height: 259px;
        background-color: #0071ef;
        border-radius: 4px;
        position: fixed;
        right: 0;
        top: 50%;
        margin-top: -129px;
        color: #ffffff;
        padding: 8px 0;
        font-size: 14px;
        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 18px;
            cursor: pointer;
            position: relative;
            &:nth-of-type(1) {
                margin-top: 9px;
            }
            >img {
                width: 26px;
                height: 26px;
                margin-bottom: 3px;
            }
            >i {
                width: 30px;
                height: 1px;
                border-bottom: solid 1px #dddddd;
                margin-top: 18px;
            }
        }
        >.one {
            &:hover {
                >span {
                    width: 252px;
                    height: 326px;
                    background-color: #ffffff;
                    box-shadow: 0px 0px 16px 0px rgba(4, 20, 62, 0.1);
                    background-image: url('./assets/Footerbar/QR_contact.png');
                    background-size: 140px 140px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    right: 86px;
                    top: 50%;
                    margin-top: -80px;
                    position: absolute;
                    &::before {
                        content: '微信咨询';
                        display: inline-block;
                        position: absolute;
                        color: #ffffff;
                        font-size: 24px;
                        color: rgba(0, 0, 0, 0.9);
                        font-weight: 700;
                        left: 50%;
                        margin-left: -48px;
                        top: 32px;
                    }
                    &::after {
                        content: '手机扫码加我微信';
                        display: inline-block;
                        position: absolute; 
                        color: #ffffff;
                        color: rgba(0, 0, 0, 0.6);
                        font-size: 16px;
                        font-weight: 700;
                        left: 50%;
                        margin-left: -64px;
                        bottom: 34px;
                    }
                }
            }
        }
        >.two {
            &:hover {
                >span {
                    width: 252px;
                    height: 326px;
                    background-color: #ffffff;
                    box-shadow: 0px 0px 16px 0px rgba(4, 20, 62, 0.1);
                    background-image: url('./assets/Footerbar/QR_contact.png');
                    background-size: 140px 140px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    right: 86px;
                    top: 50%;
                    margin-top: -163px;
                    position: absolute;
                    &::before {
                        content: '微信咨询';
                        display: inline-block;
                        position: absolute;
                        font-size: 24px;
                        color: rgba(0, 0, 0, 0.9);
                        font-weight: 700;
                        left: 50%;
                        margin-left: -48px;
                        top: 32px;
                    }
                    &::after {
                        content: '手机扫码加我微信';
                        display: inline-block;
                        position: absolute; 
                        color: #ffffff;
                        color: rgba(0, 0, 0, 0.6);
                        font-size: 16px;
                        left: 50%;
                        margin-left: -64px;
                        bottom: 34px;
                    }
                }
            }
        }
        >.three {
            >span {
                display: none;
            }
            &:hover {
                >span {
                    width: 252px;
                    height: 156px;
                    background-color: #ffffff;
                    box-shadow: 0px 0px 16px 0px rgba(4, 20, 62, 0.1);
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    text-align: left;
                    padding: 0 32px;
                    right: 86px;
                    top: 50%;
                    margin-top: -78px;
                    >i {
                        display: block;
                        font-size: 24px;
                        letter-spacing: 0px;
                        color: rgba(0, 0, 0, 0.9);
                        font-weight: 700;
                        margin-bottom: 4px;
                        &:nth-of-type(3) {
                            font-size: 14px;
                            letter-spacing: 0px;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }
            }
        }
  }
}

</style>
