<template>
    <div id="nav-bar-components">
         <div class="nav" :class="currTab">
             <div class="left" @click="tab('home')">
                 <img src="../assets/NavBar/logo_bai.png" v-if="currTab === 'case'"/>
                 <img src="../assets/NavBar/icon_logo_blue.png" v-else/>
                 <span :class="currTab">| 数字化智慧新足疗</span>
             </div>
             <div class="right"  @mouseleave="mouseleave">
                 <div class="home" :class="{'active': currTab === 'home'}" @click="tab('home')">首页</div>
                 <div 
                     :class="{'active': productAServe.includes(currTab)}"
                     @mouseenter="mouseover('product')"
                     @mouseleave="mouseleave('product')">产品和服务
                     <div class="expand-nav" v-if="isShowProduct">
                        <div class="product">
                            <div class="title">产品</div>

                            <div class="list">
                                <router-link class="item" to="/shop">
                                    <img src="../assets/NavBar/icon_Smarttubestore_homepage.png"/>
                                    <div class="item-right">
                                        <div>智慧管店</div>
                                        <div>门店数字化经营解决方案</div>
                                    </div>
                                </router-link>

                                <router-link class="item" to="/website">
                                    <img src="../assets/NavBar/icon_Microofficialwebsite_homepage.png"/>
                                    <div class="item-right">
                                        <div>微官网</div>
                                        <div>门店线上私域开店解决方案</div>
                                    </div>
                                </router-link>

                                <router-link class="item" to="/userOperation">
                                    <img src="../assets/NavBar/icon_Useroperations_homepage.png"/>
                                    <div class="item-right">
                                        <div>智能用户运营</div>
                                        <div>基于用户行为洞察的一站式运营解决方案</div>
                                    </div>
                                </router-link>

                                <router-link class="item" to="/smartMarketing">
                                    <img src="../assets/NavBar/icon_Marketing_homepage.png"/>
                                    <div class="item-right">
                                        <div>智慧营销</div>
                                        <div>数智化赋能的智能运营平台</div>
                                    </div>
                                </router-link>

                                <router-link class="item" to="/drainagePlatform">
                                    <img src="../assets/NavBar/icon_platform_homepage.png"/>
                                    <div class="item-right">
                                        <div>引流平台</div>
                                        <div>足浴垂直行业线上玩转平台流量</div>
                                    </div>
                                </router-link>

                                <router-link class="item" to="/smartPayment">
                                    <img src="../assets/NavBar/icon_payment_homepage.png"/>
                                    <div class="item-right">
                                        <div>智慧支付</div>
                                        <div>门店安全支付、便捷支付解决方案</div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="service">
                            <div class="title">服务</div>

                            <div class="list">
                                <router-link class="item" to="/advertising">
                                    <img src="../assets/NavBar/icon_Advertising.png"/>
                                    <div class="item-right">
                                        <div>广告投放</div>
                                        <div>链接海量优质流量</div>
                                    </div>
                                </router-link>

                                <router-link class="item" to="/operation">
                                    <img src="../assets/NavBar/icon_Privatedomainoperation.png"/>
                                    <div class="item-right">
                                        <div>私域运营</div>
                                        <div>私域全方位运营服务</div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                 </div>
                 <div
                    :class="{'active': solution.includes(currTab)}"
                    @mouseenter="mouseover('solution')"
                    @mouseleave="mouseleave('solution')">解决方案
                     <div class="expand-nav" v-if="isShowSolution">
                        <div class="solution">
                            <div class="title">解决方案</div>
                            <div class="list">
                                <router-link class="item" to="/wisFootMsg">
                                    <img src="../assets/NavBar/icon_Smarttubestore_homepage.png"/>
                                    <div class="item-right">
                                        <div>智慧足疗</div>
                                        <div>足疗店一体化数字经营解决方案</div>
                                    </div>
                                </router-link>

                                <router-link class="item" to="/ksfm">
                                    <img src="../assets/NavBar/icon_Microofficialwebsite_homepage.png"/>
                                    <div class="item-right">
                                        <div>K歌沐足</div>
                                        <div>K歌沐足店数字化管理解决方案</div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="list">
                                <router-link class="item" to="/wisPicEars">
                                    <img src="../assets/NavBar/icon_Smarttubestore_homepage.png"/>
                                    <div class="item-right">
                                        <div>智慧采耳</div>
                                        <div>采耳店线上线下经营管理解决方案</div>
                                    </div>
                                </router-link>

                                <router-link class="item" to="/paidMarket">
                                    <img src="../assets/NavBar/icon_Microofficialwebsite_homepage.png"/>
                                    <div class="item-right">
                                        <div>支付营销</div>
                                        <div>支付即营销数字化运营解决方案</div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                 </div>
                 <div @click="tab('case')">经典案例</div>
                 <div @click="tab('win')" :class="{'active': currTab === 'win'}">合作共赢</div>
                 <div @click="tab('aboutUs')" :class="{'active': currTab === 'aboutUs'}">关于我们</div>
             </div>
         </div>
    </div>
</template>

<script>

export default {
    name: "NavBarComponents",
    props: {
        msg: String,
    },
    data () {
        return {
            isShow: false,
            routeNameList: [
                'home', 'shop', 'website', 'userOperation', 'drainagePlatform', 'smartPayment', 'smartMarketing'
            ],
            blue: '',
            currTab: '',
            scrollTop: 0,
            isShowProduct: false,
            isShowSolution: false,
            productAServe: ['shop', 'website', 'userOperation', 'smartMarketing', 'drainagePlatform', 'smartPayment', 'advertising', 'operation'],
            solution: ['wisFootMsg', 'ksfm', 'wisPicEars', 'paidMarket']
        }
    },
    watch: {
        '$route' (route) {
            this.currTab = route.name
            if (this.routeNameList.indexOf(route.name) > -1) {
                this.blue = 'blue'
            } else {
                this.blue = ''
            }
            this.isShow = false
        }
    },
    created () {
        document.addEventListener('scroll', this.handleScroll, true)
    },
    methods: {
        mouseover (type) {
            if (type === 'product') {
                this.isShowSolution = false
                this.isShowProduct = true
            } else if (type === 'solution') {
                this.isShowProduct = false
                this.isShowSolution = true
            }
        },
        mouseleave (type) {
            if (type === 'product') {
                this.isShowSolution = false
            } else if (type === 'solution') {
                this.isShowProduct = false
            } else {
                this.isShowSolution = false
                this.isShowProduct = false
            }
        },
        tab (type) {
            this.$router.push(type)
            this.currTab = type
        }
    }
}
</script>

<style scoped lang="scss">

#nav-bar-components {
    background-color: #fff;
    >.nav {
        width: 100%;
        height: 70px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 2001;
        left: 0;
        right: 0;
        width: 100%;
        min-width: 1500px;
        >.left {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            >img {
                width: 85px;
                height: 28px;
            }
            >span {
                font-size: 16px;
                color: #0071ef;
                letter-spacing: 1px;
                font-weight: 700;
                margin-left: 10px;
                display: inline-block;
                &.case {
                    color: #fff;
                }
            }
        }
        >.right {
            height: 70px;
            line-height: 70px;
            font-size: 14px;
            margin-left: 310px;
            font-weight: 700;
            letter-spacing: 2px;
            position: relative;
            >div {
                height: 100%;
                margin: 0 16px;
                cursor: pointer;
                display: inline-block;
                &.active {
                    color: #0071ef;
                }
            }

            .expand-nav {
                width: 704px;
                background-color: #ffffff;
                position: absolute;
                top: 70px;
                left: -10px;
                z-index: 2000;
                line-height: normal;
                padding: 0 32px 32px;
                display: flex;
                transition: border-color .3s cubic-bezier(.645,.045,.355,1);
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                >.product, >.service, >.solution {
                    text-align: left;
                    >.title {
                        letter-spacing: 2px;
                        color: rgba(0, 0, 0, 0.9);
                        font-size: 14px;
                        padding: 24px 0 17px;
                        border-bottom: 1px solid #ececec;
                    }
                    >.list {
                        >.item {
                            display: flex;
                            margin-top: 24px;
                            >.item-right {
                                margin-left: 12px;
                                >div {
                                    &:nth-of-type(1) {
                                        font-size: 14px;
                                        letter-spacing: 1px;
                                        color: rgba(0, 0, 0, 0.9);
                                    }
                                    &:nth-of-type(2) {
                                        font-size: 12px;
                                        letter-spacing: 1px;
                                        color: rgba(0, 0, 0, 0.6);
                                    }
                                }
                            }
                        }
                    }
                }

                >.service {
                    margin-left: 40px;
                }
                >.solution {
                    width: 100%;
                    // display: flex;
                    >.list {
                        width: 50%;
                        display: inline-block;
                    }
                }
            }
        }
    }
    >.case {
        background-color: #f38032;
        color: #fff;
    }
}
</style>
