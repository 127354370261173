import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./script/axios.js"
import request from './script/api'
// import "swiper/swiper.min.css"
// import mitt from 'mitt'

const app = createApp(App)
// app.config.globalProperties.emitter = mitt()
app.config.globalProperties.$axios = axios
app.config.globalProperties.$request = request

let AppID = 'wxe0f651cd4573c57a'

app.use(store).use(router).use(router).mount("#app");
